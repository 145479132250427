import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Pagination, paginationCalc } from '../components/pagination';
import { BlogCard, BlogCardRow, blogListSlice } from '../components/blog-card';
import { BigBanner } from '../components/adsense';

const BlogListTemplate = props => {
  // タイトル等
  const siteName = props.data.site.siteMetadata.title;
  let title = `${siteName}`;
  let description = `${siteName}はプログラム学習記録やよく使うコード・設定などの情報を発信していきます。`;
  if (props.nowPage > 1) {
    title = `新着記事 | ${siteName} - Page${props.nowPage}`;
    description = `新着記事${props.nowPage}ページ目です。`;
  }

  // ブログデータの列
  let blogRow = 2;
  // createPageデータ
  let context = props.pageContext;
  // ブログデータ
  let blogPosts = props.data.allMdx.nodes;
  // ブログデータを列数分に分割
  let blogSilceList = blogListSlice(blogPosts, blogRow);

  // ブログカードリスト
  let blogList = [];
  for (let i = 0; i < blogSilceList.length; i++) {
    // 4つ目(2行目)に広告
    if (i === 2) {
      blogList.push(
        <BigBanner key={blogList.length} className="w-full mb-8" />
      );
    }
    blogList.push(
      <BlogCardRow key={blogList.length} className="lg:flex lg:justify-between">
        {blogSilceList[i].map((blogData, k) => (
          <BlogCard key={k} blogData={blogData} />
        ))}
      </BlogCardRow>
    );
  }

  // ページネーション数
  let { start, end } = paginationCalc(
    context.totalListPage,
    context.prev,
    context.next,
    context.nowPage
  );
  return (
    <Layout
      title={title}
      description={description}
      location={props.location}
      pageType="top"
    >
      {/* ブログリスト */}
      <div>{blogList}</div>
      {/* ページネーション */}
      <Pagination
        start={start}
        end={end}
        nowPage={context.nowPage}
        rootPath="/page"
        firstPath="/"
      />
    </Layout>
  );
};

export default BlogListTemplate;

export const blogListQuery = graphql`
  query blogListQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: $limit
      skip: $skip
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        slug
        frontmatter {
          title
          description
          date(formatString: "YYYY-MM-DD HH:mm")
          category
          tags
          heroImage {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
    }
  }
`;
